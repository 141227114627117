<template>
<div class="wrapper wrapper-content animated fadeInRight">
  <div class="row">
    <div class="col-lg-12">
      <div class="tabs-container">
        <ul class="nav nav-tabs">
          <!-- <li><a class="nav-link active" data-toggle="tab" href="#tab-1"> Record </a></li> -->
        </ul>

        <div class="tab-content">
          <div class="tab-pane active">
            <div class="panel-body">
              <fieldset>

                <div class="form-group row"><label class="col-sm-2 col-form-label">메일 템플릿</label>
                  <div class="col-sm-10">
                    <select v-model="template" class="form-control">
                      <option value="">-- 템플릿 선택 --</option>
                      <option v-for="option in templates" :value="option.value">{{option.label}}</option>
                    </select>
                    <span class="form-text">발송할 메일 템플릿을 선택해주세요.</span>
                  </div>
                </div>

                <div class="hr-line-dashed"></div>

                <div class="form-group row"><label class="col-sm-2 col-form-label">수신 이메일 주소</label>
                  <div class="col-sm-10">
                    <textarea class="form-control" :rows="10" v-model="recipient"/>
                    <span class="form-text"></span>
                  </div>
                </div>

                <div class="hr-line-dashed"></div>

                <div class="form-group row"><label class="col-sm-2 col-form-label">제목</label>
                  <div class="col-sm-10">
                    <input v-model="title" class="form-control" type="text" name="title" />
                    <span class="form-text"></span>
                  </div>
                </div>

                <div class="hr-line-dashed"></div>

              </fieldset>

              <div class="form-group row">
                <div class="actions">
                  <button class="btn btn-white btn-sm" @click="onClickCancel">Cancel</button>
                  <button class="btn btn-success btn-sm" :disabled="sending" @click="onClickSubmit">Send</button>
                </div>
              </div>

            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import config from "@/config"
import Editor from "@/components/utils/Editor"
import ExtraEditor from "@/components/utils/ExtraEditor"
import ResourceEditor from "@/components/utils/ResourceEditor"

export default {
  data() {
    return {
      mode: 'unset',
      model: {},
      sending: false,
      title: '',
      recipient: '',
      template: '',
      templates: [],
      timestamp: +new Date()
    }
  },
  mounted() {
    this.$data.mode = this.$route.meta.mode
    this.$data.model = this.$route.meta.model

    this.loadTemplates()

    // if (this.mode === 'create') {
    //   this.$data.data['resource_key'] = this.$utils.uuid()
    // }
    // else {
    //   this.loadData()
    // }
  },

  watch: {
    template(value, oldValue) {
      if(value === 'host-announcement') {
        this.title = "럭스테이 호스트가 되신 걸 환영합니다!"
      }
    }
  },
  methods: {
    loadTemplates() {
      this.$store.dispatch('mail/list', { page: 1, size: 100 })
        .then((data) => {
          let { items, total } = data
          let templates = []
          this._.forEach(items, (item) => {
            templates.push({
              label: item.description,
              value: item.name
            })
          })
          this.$data.templates = templates
        })
        .catch(e => {
          this.$toast.error(e.message)
        })
    },

    onClickCancel(e) {
      this.$router.push({
        name: this.model.views.list.name,
        params: {}
      })
    },

    onClickSubmit() {
      this.$dialog
        .confirm(`해당 템플릿으로 ${this.parseRecipient(this.recipient)}에게 발송할까요?`, {
          okText: 'Confirm',
          cancelText: 'Cancel',
        })
        .then((dialog) => {
          this.onSubmit()
        })
        .catch(() => {

        });
    },

    onSubmit() {
      let params = {
        name: this.template,
        title: this.title,
        recipient: this.parseRecipient(this.recipient)
      }

      this.$data.sending = true
      this.$store.dispatch('mail/send', params)
        .then((data) => {
          this.$toast.success("발송되었습니다.")
        })
        .catch(e => {
          this.$toast.error(e.message)
        })
        .finally(() => {
          this.$data.sending = false
        })
    },

    parseRecipient( string = '') {
      return string.split("\n");
    }
  },
  components: {
    Editor, ExtraEditor, ResourceEditor
  }
}
</script>
